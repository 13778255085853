export const black = '#272626';
export const black2 = '#383636fa';
export const black4 = '#707070';
export const offWhite = '#f7f4eb';
export const offWhite2 = '#F2ECDF';
export const offWhite3 = '#EFEFEF';
export const offWhite5 = '#FAF9F6';
export const green = '#bed0a2';
export const green2 = '#637c5a';
export const green3 = '#A6AD99';
export const red = '#f5785a';
export const red2 = '#dc4146';
export const orange = '#ec6b4c';
export const orange2 = '#f57a5b';
export const orange3 = '#00000029';
export const orange4 = '#E47F62';
export const cream = '#e8e2d3';
export const cream2 = '#f0eadc';
export const white = '#fff';
export const white2 = '#FAF9F6';
export const grey = '#b7b7b7';
export const grey2 = '#919191';
export const grey3 = '#646464';
export const grey4 = '#62615C';
export const grey6 = '#EEECE8';
export const grey7 = '#F4F2EE';
export const blue = '#3898ec';
export const Light = '#ebe4d5';
export const afterPayBlue = '#b2fce4';
export const proteinBlack = '#000';
export const greenBg = '#637D59';
export const giftGreen = '#BED0A2';
export const brownGiftCard = '#E8E2D3';
export const yellow = '#ffd326';
export const brown1 = '#E0E0E0';
export const brown2 = '#F2EDE0';
export const strongLight = '#ddd7c6';
export const nHaas55 = 'NHaasGroteskDSPro-55Rg';
export const nHaas65 = 'NHaasGroteskDSPro-65Md';
export const nHaas75 = 'NHaasGroteskTXPro-75Bd';
export const nHaasTx65 = 'NHaasGroteskTXPro-65Md';
export const nHaasTx55 = 'NHaasGroteskTXPro-55Rg';
export const solaire = 'SolaireDT-Regular';
export const grotesk = 'Neue haas Grotesk';
export const homemadeApple = 'Homemade Apple';
export const september = 'September';
export const inter = 'Inter';
export const inter600 =  'InterBold';
export const inter400 = 'InterRegular';
export const inter300 = 'InterLight';
export const ammerHandwriting = 'Ammer Handwriting';
